.image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .image-upload h2 {
    margin-bottom: 20px;
  }
  
  .image-upload input {
    margin: 10px 0;
  }
  
  .image-upload img {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 10px;
  }
  